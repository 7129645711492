import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../AppContext";
import { ModalCreateAccount } from "../components/Modals/ModalCreateAccount";
import { LoginSuccess } from "../components/Modals/ModalLoginSuccess";
import { MemorialContext } from "../MemorialContext";
import { LoginType } from "../components/AppType";

export const Login = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [loginFailedDisplay, setLoginFailedDisplay] = useState<boolean>(false);
    const [loginFailedColor, setLoginFailedColor] = useState<string>('');
    const [loggedOut, setLoggedOut] = useState<boolean>(true);
    const [loggedOutState, setLoggedOutState] = useState<boolean>(true);
    const [userEmail, setUserEmail] = useState<string>('');
    const [inputFocus, setInputFocus] = useState<boolean>(false);

    const baseUrlLogin: any = process.env.REACT_APP_BASE_LOGIN;
    const loginEndpoint = `${baseUrlLogin}`;

    const emailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);

    // if (userInput) {
    //     userInput.defaultValue = userDisplay;
    // }

    // useEffect(() => {
    //     setUserDisplay(commonData.email);
    // }, [loggedOut]);
    
    const [formState, setFormState] = useState<LoginType>({
        email: '',
        password: ''
    });
    
    const handleFormChange = (e: React.ChangeEvent<any>) => {
        if (e.target.type === "email" || e.target.type === "password") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));
        }

        if (e.target.id === "emailNew") {
            setUserEmail(() => {
                const newEmail = e.target.value;
                return newEmail;
            });
        }
    }

    
    const passwordGroup = document.getElementsByClassName('form-password');
    const password = passwordGroup[0] as HTMLInputElement;

    const handlePasswordShow = () => {
        if (password) {
            if (password.type === "password") {
                password.type = "text";
            } else {
                password.type = "password";
            }
        }
    }

    const postLoginData = async (endpoint: string, data: any, success: any, failure: any) => {

        console.log("LOGIN DATA", JSON.stringify(data));
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                setCommonData(await response.json());
                document.getElementById('loginSuccessModal')?.click();
            } else {
                failure("Login Failed", response);
                setLoginFailedDisplay(true);
                setLoginFailedColor('#DC3545');
            }
        } catch (error) {
            failure(error);
        }
    }

    const handleLoginClick = (event: React.MouseEvent): void => {
        postLoginData(loginEndpoint, formState,
            (response:any) => {
                console.log("TOKEN", response);
            },
            (response:any) => {
                console.log("FAILED",response);
        })
    }

    const loadUser = (val: string, event: React.MouseEvent) => {
        event.preventDefault();

        if (emailInputRef.current && passwordInputRef.current) {
            emailInputRef.current.value = val;
            passwordInputRef.current.value = "PWD"
            passwordInputRef.current.type = "text";

            setFormState((formState) => ({
                ...formState, 
                email: val,
                password: 'PWD'
            }));
        }
    }

    const handleInputFocus = (active: boolean) => {
        setInputFocus(active);
    }

    return (
        <>
            <LoginSuccess />

            {!loggedOut &&
                <div className="page-wrapper page-login">
                    <div className="login-top d-flex justify-content-center">
                        <div className="dropdown login-user-select">
                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">User</button>
                            <ul className="dropdown-menu">
                                <li><button onClick={(e) => {loadUser('aaa@acme.com', e)}} className="dropdown-item">Tina Halevi</button></li>
                                <li><button onClick={(e) => {loadUser('bbb@acme.com', e)}} className="dropdown-item">Lionel Turner</button></li>
                                <li><button onClick={(e) => {loadUser('ccc@acme.com', e)}} className="dropdown-item">Joan Wilkinson</button></li>
                                <li><button onClick={(e) => {loadUser('ddd@acme.com', e)}} className="dropdown-item">Patrick Jordan</button></li>
                                <li><button onClick={(e) => {loadUser('eee@acme.com', e)}} className="dropdown-item">Jan Grinch</button></li>
                            </ul>
                        </div>

                        <p className="login-title">{ t("Login.heading", {ns: "components"}) }</p>

                        <div className="btn btn-modal-close" onClick={() => {setLoggedOut(true); setLoggedOutState(true)}} >
                            <img src="/images/modal-close-x.svg"/>
                        </div>

                    </div>
                    
                    <form className="login-form p-3">
                        <div className="d-flex flex-column justify-content-between h-100">
                                
                            {/* Form inputs here  */}
                            <div className="d-flex flex-column gap-4">
                                <div>
                                    <label htmlFor="email" className="form-label">{ t("Login.Form.Email.label", {ns: "components"}) }</label>
                                    <input ref={emailInputRef} id="email" name="email" onChange={handleFormChange} type="email" className="form-control" placeholder={ t("Login.Form.Email.placeholder", {ns: "components"}) } style={{borderColor: loginFailedColor}} required />
                                </div>

                                <div className="mb-4" style={{position: "relative"}}>
                                    <label htmlFor="password" className="form-label">{ t("Login.Form.Password.label", {ns: "components"}) }</label>
                                    <input ref={passwordInputRef} id="password" name="password" onChange={handleFormChange} type="password" className="form-control form-password" placeholder={ t("Login.Form.Password.placeholder", {ns: "components"}) } style={{borderColor: loginFailedColor}} required />
                                    <img src="/images/fl-eye.svg" onClick={handlePasswordShow} style={{position: "absolute", top: "63%", right: "16px", cursor: "pointer"}} alt="" />
                                </div>

                                { loginFailedDisplay &&
                                    <div className="failed-login-block">
                                        <p>Incorrect email or password</p>
                                    </div>
                                }

                                <div className="login-footer d-flex justify-content-center">
                                    <a href="/">{ t("Login.Account.forgotPassword", {ns: "components"}) }</a>
                                </div>
                            </div>


                            <div className="login-footer d-flex flex-column gap-3 mb-2">

                                {/* Form submit here  */}
                                <button type="button" onClick={handleLoginClick} className="btn fl-btn-login">{ t("Login.heading", {ns: "components"}) }</button>
                                <p>{ t("Login.Account.copy", {ns: "components"}) } <span onClick={() => {setLoggedOut(true); setLoggedOutState(true)}} style={{textDecoration: 'underline'}}>create an account</span></p>
                            </div>
                        </div>
                    </form>

                </div>
            }

            {loggedOut &&
                <>
                    <div className="logged-out-wrapper d-flex flex-column justify-content-between p-4 pb-5">
                        <div className={`logged-out-background ${inputFocus && 'active'}`} />

                        <div className="d-flex w-100">
                            <img src="/images/fl-logo-text.svg" height={20} alt=""/>
                        </div>

                        <div className="logged-out-bottom">
                            <div className="title-text">
                                <p>Memories fade.</p>
                                <p>Keep their stories alive.</p>
                            </div>

                            <div className="login-form" style={{height: 'auto'}}>
                                <label htmlFor="emailNew" className="form-label">{ t("Login.Form.Email.label", {ns: "components"}) }</label>
                                <input id="emailNew" name="emailNew" onChange={handleFormChange} onFocus={() => handleInputFocus(true)} onBlur={() => handleInputFocus(false)} type="email" className="form-control" placeholder="name@domain.com" />
                            </div>

                            <ModalCreateAccount loggedOutState={loggedOutState} email={userEmail} />

                            <p className={`account-exists-text ${inputFocus && 'active'}`}>I already have an account. <span onClick={() => {setLoggedOut(false); setLoggedOutState(false)}} style={{textDecoration: "underline", cursor: "pointer"}}>Login</span></p>
                        </div>
                    </div>
                </>
            }
        </>
    );
}


{/* <button id="buttonLogin" className="btn fl-btn-dark-login" onClick={() => {setLoggedOut(false); setLoggedOutState(false)}}>Login</button> */}
