import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { CreateMemorialType, familyTreeUpdateType } from "../AppType";
import { fetchMultiSourceRecords, postFormData } from "../../utils/AppUtil";
import { Modal } from "react-bootstrap";
import { GoogleMap, useJsApiLoader, Marker, Libraries } from '@react-google-maps/api';

import PlacesAutocomplete, {
       geocodeByAddress,
       getLatLng,
  } from 'react-places-autocomplete';
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const containerStyle = {
    width: '100vw',
    height: '100dvh',
}

const center = {
    lat: -29.80,
    lng: 24.84
}

const libraries: Libraries = ["places"];

type ModalProps = {
    emptyState: boolean,
    relation: string,
    link: string,

    fromFamilyTree: boolean,
    selectedNode: familyTreeUpdateType

    showModal?: boolean;
    closeModal?: () => void;
}

type FamilyTreeNodeData = {
    posX: number,
    posY: number,
    handleFrom: string,
    handleTo: string
}

export const ModalCreateMemorial: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [religions, setReligions] = useState<React.ReactElement[]>();
    const [relationships, setRelationships] = useState<React.ReactElement[]>();
    const [formStep, setFormStep] = useState<number>(0);
    const [modalContentShow, setModalContentShow] = useState<boolean>(true);
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [modalSuccessMenu, setModalSuccessMenu] = useState<boolean>(false);
    const [show, setShow] = useState(false);

    const [formProgressHide, setFormProgressionHide] = useState<string>("");
    const [modalAnimation, setModalAnimation] = useState<string>("modal-deactivate-scroll");
    const [isTransitioning, setIsTransitioning] = useState<Boolean>(false);

    const refFirstName = useRef<HTMLInputElement>(null!);

    const refBirthDateDay = useRef<HTMLInputElement>(null!);
    const refBirthDateMonth = useRef<HTMLInputElement>(null!);
    const refBirthDateYear = useRef<HTMLInputElement>(null!);
    
    const refDeathDateDay = useRef<HTMLInputElement>(null!);
    const refDeathDateMonth = useRef<HTMLInputElement>(null!);
    const refDeathDateYear = useRef<HTMLInputElement>(null!);

    const [invalidBirthDate, setInvalidBirthDate] = useState<boolean>(false);
    const [invalidBirthDay, setInvalidBirthDay] = useState<boolean>(false);
    const [invalidBirthMonth, setInvalidBirthMonth] = useState<boolean>(false);
    const [invalidBirthYear, setInvalidBirthYear] = useState<boolean>(false);

    const [invalidDeathDate, setInvalidDeathDate] = useState<boolean>(false);
    const [invalidDeathDay, setInvalidDeathDay] = useState<boolean>(false);
    const [invalidDeathMonth, setInvalidDeathMonth] = useState<boolean>(false);
    const [invalidDeathYear, setInvalidDeathYear] = useState<boolean>(false);

    const invalidBorder: string = '3px solid #DC3545';

    const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    const [markerPosition, setMarkerPosition] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
    const [zoom, setZoom] = useState<number>(13);
    const [searchOpen, setsearchOpen] = useState<boolean>(false);
    const [searchSelected, setsearchSelected] = useState<boolean>(false);
    const [relation, setRelation] = useState<string>('');
    const [gender, setGender] = useState<string>('');

    const [familyTreeNodeData, setFamilyTreeNodeData] = useState<FamilyTreeNodeData>();

    const [jewishReligionId, setJewishReligionId] = useState<string>("");
    const [isBeforeSunset, setIsBeforeSunset] = useState<boolean>(false);

    const [address, setAddress] = useState("");

    const navigate = useNavigate();

    // Disable vertical scrolling on body when modal is open
    const useLockBodyScroll = (showModal: boolean, modalRef: MutableRefObject<HTMLElement | null>): void => {
        useEffect(() => {
            const preventScroll = (e: TouchEvent) => {
                if (modalRef.current && modalRef.current.contains(e.target as Node)) {
                    return; // Allow scrolling inside the modal
                }

                e.preventDefault(); // Prevent scrolling outside the modal (i.e., body scroll)
            };

            if (showModal) {
                document.body.style.position = 'fixed'; // Fixes the body position
                window.addEventListener('touchmove', preventScroll, { passive: false });
            } else {
                document.body.style.position = ''; // Revert body positioning
                window.removeEventListener('touchmove', preventScroll);
            }

            return () => {
                // Cleanup when modal is closed
                document.body.style.position = '';
                window.removeEventListener('touchmove', preventScroll);
            };
        }, [showModal, modalRef]);
    };

    const modalRef = useRef<HTMLDivElement | null>(null);
    useLockBodyScroll(show, modalRef);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    useEffect(() => {
        if (props.fromFamilyTree) {
            setRelation(props.relation);

            setFormState((formState) => ({
                ...formState, 
                firstName: props.selectedNode?.proxyFirstName ?? '',
                lastName: props.selectedNode?.proxyLastName ?? ''
            }));
        }

        if (props.showModal) setShow(true);
    }, [props.showModal])

    // Google Maps
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDX5iQx8lsEcck9KXBSRRLNh7oIwN3hF0U',
        libraries: libraries
    })

    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
        setMap(map);
        map.setZoom(zoom)
    }, []);
    
    const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
        setMap(null);
    }, []);

    // const handleMapClick = (event: google.maps.MapMouseEvent) => {
    //     if (event.latLng) {
    //         setMarkerPosition({
    //             lat: event.latLng.lat(),
    //             lng: event.latLng.lng()
    //         });

    //         // setFormState((formState) => ({
    //         //     ...formState, 
    //         //     locationLat: event.latLng.lat(),
    //         //     locationLng: event.latLng.lng(),
    //         //     locationDesc: address
    //         // }));
    //     }
    // };

    const handleLocationSelect = async (value: any) => {
        const results = await geocodeByAddress(value);
        const coords = await getLatLng(results[0]);

        setAddress(value);
        setMarkerPosition({lat: coords.lat, lng: coords.lng});
        setUserLocation({lat: (coords.lat - 0.015), lng: coords.lng});

        setFormState((formState) => ({
            ...formState, 
            locationLat: coords.lat,
            locationLong: coords.lng,
            locationDesc: address
        }));

    }

    // Initialise form data
    const formData: CreateMemorialType = {
        firstName: "",
        lastName: "",
        gender: "",

        birthDateDay: "",
        birthDateMonth: "",
        birthDateYear: "",

        deathDateDay: "",
        deathDateMonth: "",
        deathDateYear: "",
        
        birthDate: "",
        deathDate: "",
        obituary: "",
        religiousBeliefId: "",
        userMemorialRelationshipId: "",

        locationDesc: "",
        locationLong: 0,
        locationLat: 0
    }
    const [formState, setFormState] = useState<CreateMemorialType>(formData);

    const formDataFamilyTree: familyTreeUpdateType = {
        treeNodeId: '',
        userId: '',
        memorialId: '',
        relationship: '',
        data: {
            posX: 0,
            posY: 0,
            connections: [{
                handleTo: "",
                handleFrom: "",
                fromTreeNodeId: ""
            }]
        }
    }
    const [formStateFamilyTree, setFormStateFamilyTree] = useState<familyTreeUpdateType>(formDataFamilyTree);

    // Fetch data
    useEffect(() => {

        const endpoints = ["memorial/religious_belief", "user/relationship"];
        fetchMultiSourceRecords(commonData.token, endpoints, processData)
        .catch((error) => {
            console.log("useEffect ERROR:", error);
        });
    }, [commonData.token]);

    // Process Data
    const processData = (results: any[]) => {

        if (results[0]) {
            const religions: any = results[0];
            const relationships: any = results[1];

            processDataReligions(religions);
            processDataRelationships(relationships);
        }
    }

    // Build religions JSX
    const processDataReligions = (responseJson: any) => {
        const result: React.ReactElement[] = [];
        let key: number = 0;

        responseJson.forEach((record: any, index: any) =>  {

            if (record.name === "Jewish") {
                setJewishReligionId(record.religiousBeliefId)
            }

            result.push(
                <option key={key} value={record.religiousBeliefId} className="select-option">{record.name}</option>
            );
            key++;
        });
        setReligions(result);
    }

    // Build relashionships JSX
    const processDataRelationships = (responseJson: any) => {
        const result: React.ReactElement[] = [];
        let key: number = 0;

        responseJson.forEach((record: any, index: any) =>  {
            result.push(
                <option key={key} value={record.userMemorialRelationshipId} className="select-option">{record.name}</option>
            );
            key++;

        });
        setRelationships(result);
    }

    // Validation (day)
    const validateDay = (value: string): string => {
        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            if (formStep === 1) {
                setInvalidBirthDay(true);
                
            } else if (formStep === 2) {
                setInvalidDeathDay(true);
            }
            
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            if (formStep === 1) {
                setInvalidBirthDay(false);
                
            } else if (formStep === 2) {
                setInvalidDeathDay(false);
            }

            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            if (formStep === 1) {
                setInvalidBirthDay(false);

            } else if (formStep === 2) {
                setInvalidDeathDay(false);
            }

            return numberValue.toString();

        } else {
            if (formStep === 1) {
                setInvalidBirthDay(true);

            } else if (formStep === 2) {
                setInvalidDeathDay(true);
            }

            return 'invalid';
        }
    }

    // Validation (month)
    const validateMonth = (value: string): string => {
        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            if (formStep === 1) {
                setInvalidBirthMonth(true);
                
            } else if (formStep === 2) {
                setInvalidDeathMonth(true);
            }
            
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            if (formStep === 1) {
                setInvalidBirthMonth(false);

            } else if (formStep === 2) {
                setInvalidDeathMonth(false);
            }

            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            if (formStep === 1) {
                setInvalidBirthMonth(false);

            } else if (formStep === 2) {
                setInvalidDeathMonth(false);
            }

            return numberValue.toString();

        } else {
            if (formStep === 1) {
                setInvalidBirthMonth(true);

            } else if (formStep === 2) {
                setInvalidDeathMonth(true);
            }

            return 'invalid';
        }
    }

    // Validation (year)
    const validateYear = (value: string): string => {

        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            if (formStep === 1) {
                setInvalidBirthYear(true);
                
            } else if (formStep === 2) {
                setInvalidDeathYear(true);
            }
            
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            if (formStep === 1) {
                setInvalidBirthYear(false);

            } else if (formStep === 2) {
                setInvalidDeathYear(false);
            }

            return '000' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            if (formStep === 1) {
                setInvalidBirthYear(false);

            } else if (formStep === 2) {
                setInvalidDeathYear(false);
            }

            return '00' + numberValue.toString();

        } else if (numberValue >= 100 && numberValue < 1000) {
            if (formStep === 1) {
                setInvalidBirthYear(false);

            } else if (formStep === 2) {
                setInvalidDeathYear(false);
            }

            return '0' + numberValue.toString();

        } else if (numberValue >= 1000 && numberValue < 10000) {
            if (formStep === 1) {
                setInvalidBirthYear(false);

            } else if (formStep === 2) {
                setInvalidDeathYear(false);
            }

            return numberValue.toString();

        } else {
            if (formStep === 1) {
                setInvalidBirthYear(true);

            } else if (formStep === 2) {
                setInvalidDeathYear(true);
            }

            return 'invalid';
        }
    }
    
    // Form change (non date fields)
    const handleFormChange = (e: React.ChangeEvent<any>) => {

        if (e.target.type === "text") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));
        }

        if (e.target.type === "select-one") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));
        }
    }

    // Form change (date fields)
    const handleFormChangeDate = (e: React.ChangeEvent<any>) => {

        if (e.target.type === "text") {

            // Birth Date
            if (e.target.id === "birthDateDay") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateDay(e.target.value)
                }));

                if (e.target.value.length === 2) {
                    refBirthDateMonth.current.focus();
                }
            }

            if (e.target.id === "birthDateMonth") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateMonth(e.target.value)
                }));

                if (e.target.value.length === 2) {
                    refBirthDateYear.current.focus();
                }
            }

            if (e.target.id === "birthDateYear") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateYear(e.target.value)
                }));
            }

            // - - - - - Death Date - - - - -
            if (e.target.id === "deathDateDay") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateDay(e.target.value)
                }));

                if (e.target.value.length === 2) {
                    refDeathDateMonth.current.focus();
                }
            }

            if (e.target.id === "deathDateMonth") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateMonth(e.target.value)
                }));

                if (e.target.value.length === 2) {
                    refDeathDateYear.current.focus();
                }
            }

            if (e.target.id === "deathDateYear") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateYear(e.target.value)
                }));
            }
        }
    }

    const handleShow = () => {
        setFormStep(0);
        setModalSuccessMenu(false);
        setModalContentShow(true);
        setModalSuccess(false);
        setShow(true);
    }

    const handleClose = () => {
        if (props.closeModal) props.closeModal();
        setShow(false);
        setFormStep(0);
    }

    const handleCloseDelay = () => {
        setTimeout(() => {
            setShow(false);
        }, 1000);
    }

    // Navigate form next
    const handleClickNext = () => {
        // if (formStep === 0) {
        //     setTimeout(() => {
        //         refBirthDateDay.current.focus();
        //     }, 500);
        // }

        if (formStep === 1) {
            if (formState.birthDateDay != 'invalid' && formState.birthDateMonth != 'invalid' && formState.birthDateYear != 'invalid') {
                setFormState((formState) => ({
                    ...formState, 
                    birthDate: formState.birthDateYear + "-" + formState.birthDateMonth + "-" + formState.birthDateDay
                }));
                setFormStep(formStep + 1);
                
                setTimeout(() => {
                    refDeathDateDay.current.focus();
                }, 500);
            } else {
                setInvalidBirthDate(true);
            }

        } else if (formStep === 2) {

            if (formState.deathDateDay != 'invalid' && formState.deathDateMonth != 'invalid' && formState.deathDateYear != 'invalid') {
                setFormState((formState) => ({
                    ...formState, 
                    deathDate: formState.deathDateYear + "-" + formState.deathDateMonth + "-" + formState.deathDateDay
                }));

                setFormStep(formStep + 1);

            } else {
                setInvalidDeathDate(true);
            }

        } else if (formStep === 4) {
            // setFormProgressionHide("animate__animated animate__fadeOutLeft animate__fast");
            // setFormStep(formStep + 1);

            // setTimeout(() => {
            //     setFormProgressionHide("");
            // }, 1000);
            handlePostSkipLocal();
        } else {
            setFormStep(formStep + 1);
        }
    }

    // Navigate form back
    const handleClickBack = () => {
        setInvalidBirthDate(false);
        
        // if (formStep === 1) {
        //     setTimeout(() => {
        //         refFirstName.current.focus();
        //     }, 500);
        // }
        
        // if (formStep === 2) {
        //     setTimeout(() => {
        //         refBirthDateDay.current.focus();
        //     }, 500);
        // }
        
        // if (formStep === 3) {
        //     setTimeout(() => {
        //         refDeathDateDay.current.focus();
        //     }, 500);
        // }

        setFormStep(c => c - 1)
        setIsTransitioning(true);
        setTimeout(() => {
            setIsTransitioning(false);
        }, 500);
    }

    //Open & close search
    const handleSearchOpen = () => {
        setsearchOpen(c => !c);
    }

    const handleSearchInput = () => {
        if (!searchOpen) {
            setsearchOpen(true);
            setsearchSelected(false);
        }
    }

    const handleSearchSelect = () => {
        if (searchOpen) {
            setsearchOpen(false);
            setTimeout(() => {
                setsearchSelected(true);
            }, 1000);
        }
    }

    const handleBeforeSunset = (status: boolean) => {
        setIsBeforeSunset(status);
    }

    // Post data
    const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        postFormData(
            commonData.token,
            'memorial',
            formState,
            (response: any) => {
                setFormState(formData);
            },
            (error: any) => { // error
            }
        );

        setModalSuccessMenu(true);

        setTimeout(() => {
            setModalSuccess(true);
        }, 1000);

        setTimeout(() => {
            handleCloseDelay();
        }, 3000);

    }

    // e: React.FormEvent<HTMLButtonElement>
    const handlePostSkipLocal = async () => {
        // e.preventDefault();

        setFormState((formState) => ({
            ...formState, 
            locationLat: 0,
            locationLong: 0,
            locationDesc: ""
        }));

        console.log(formState);

        postFormData(
            commonData.token,
            'memorial',
            formState,
            (response: any) => {
                setFormState(formData);
                handlePostFamilyTree(response.memorialId);
            },
            (error: any) => { // error
            }
        );

        setModalSuccessMenu(true);

        setTimeout(() => {
            setModalSuccess(true);
        }, 1000);

        setTimeout(() => {
            handleCloseDelay();
        }, 3000);
    }

    const handlePostFamilyTree = (memorialId: string) => {

        if (props.fromFamilyTree) {
            postFormData(
                commonData.token,
                `user/loggedIn/familyTree`,
                [{
                    treeNodeId: props.selectedNode?.treeNodeId,
                    userId: props.selectedNode?.userId,
                    memorialId: memorialId,
                    relationship: props.selectedNode?.relationship,
                    data: {
                        posX: props.selectedNode?.data.posX,
                        posY: props.selectedNode?.data.posY,
                        handleTo: props.selectedNode?.data.connections[0].handleTo,
                        handleFrom: props.selectedNode?.data.connections[0].handleFrom,
                        fromTreeNodeId: props.selectedNode?.data.connections[0].fromTreeNodeId
                    }
                }],
                (response: any) => {console.log(response)},
                (error: any) => {console.log(error)}
            );
        } else {
            postFormData(
                commonData.token,
                `user/loggedIn/familyTree`,
                [{
                    memorialId: memorialId,
                    relationship: relation,
                    data: {
                        posX: familyTreeNodeData?.posX,
                        posY: familyTreeNodeData?.posY,
                        handleTo: familyTreeNodeData?.handleTo,
                        handleFrom: familyTreeNodeData?.handleFrom,
                        fromTreeNodeId: 'originNode'
                    }
                }],
                (response: any) => {console.log(response)},
                (error: any) => {console.log(error)}
            );
        }
    }

    const handleSetRelationShip = (relation: string, posX: number, posY: number, handleFrom: string, handleTo: string) => {
        setRelation(relation);
        setFamilyTreeNodeData({
            posX: posX,
            posY: posY,
            handleFrom: handleFrom,
            handleTo: handleTo
        });
    }
    
    return (
        <>
            {props.fromFamilyTree ?
                <></>
                :
                props.emptyState ?
                    <button className="memorial-system-tile-button" onClick={handleShow}>Create a memorial</button>
                    :
                    <div className="home-memorial-placeholder-tile" onClick={handleShow} style={{cursor: "pointer"}}>
                        <img className="mb-2" src="/images/fl-plus-lg.svg" />
                        <p className="timeline-btn-title">Create a</p>
                        <p className="timeline-btn-title">memorial</p>
                    </div>
            }

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose} style={{height: "100dvh"}}>
                {modalContentShow &&
                    <>
                        <Modal.Header>
                            {formStep === 0 ?
                                <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClose}>
                                    <img src="/images/modal-back-chev.svg" />
                                </div>
                                :
                                <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClickBack}>
                                    <img src="/images/modal-back-chev.svg" />
                                </div>
                            }
                            <p className="modal-title">Create a memorial</p>
                            <div className="btn btn-modal-close" onClick={handleClose} style={{zIndex: 999}}><img src="/images/modal-close-x.svg"/></div>
                        </Modal.Header>

                        <Modal.Body ref={modalRef}>
                            <div className={`form-progress form-progress-absolute ${formProgressHide}`}>
                                <img src={`/images/fl-progress-heart.svg`} className={`form-progress-current form-progress-${formStep}`} />
                                <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                                <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                                <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                                <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                                <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                                <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                                <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                            </div>

                            <div className="form-container">

                                {/* 0: Select relation */}
                                <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep > 0 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                                    <p className="modal-heading mb-4">Who would you like to remember?</p>

                                    <div className="login-form scroll-active">
                                        <div className="d-flex flex-column gap-3">
                                            <div className={`create-memorial-radio-wrapper w-100 ${relation === 'Father' && 'selected'}`} onClick={(() => handleSetRelationShip('Father', -82, -250, 'top', 'bottom'))}>
                                                <div className={`create-memorial-radio ${relation === 'Father' && 'selected'}`}>
                                                    <div className="radio-selected"></div>
                                                </div>
                                                <p>Father</p>
                                            </div>

                                            <div className={`create-memorial-radio-wrapper w-100 ${relation === 'Mother' && 'selected'}`} onClick={(() => handleSetRelationShip('Mother', 82, -250, 'top', 'bottom'))}>
                                                <div className={`create-memorial-radio ${relation === 'Mother' && 'selected'}`}>
                                                    <div className="radio-selected"></div>
                                                </div>
                                                <p>Mother</p>
                                            </div>

                                            <div className={`create-memorial-radio-wrapper w-100 ${relation === 'Partner' && 'selected'}`} onClick={(() => handleSetRelationShip('Partner', 164, 0, 'right', 'left'))}>
                                                <div className={`create-memorial-radio ${relation === 'Partner' && 'selected'}`}>
                                                    <div className="radio-selected"></div>
                                                </div>
                                                <p>Partner</p>
                                            </div>

                                            <div className={`create-memorial-radio-wrapper w-100 ${relation === 'Brother' && 'selected'}`} onClick={(() => handleSetRelationShip('Brother', -164, 0, 'left', 'right'))}>
                                                <div className={`create-memorial-radio ${relation === 'Brother' && 'selected'}`}>
                                                    <div className="radio-selected"></div>
                                                </div>
                                                <p>Brother</p>
                                            </div>

                                            <div className={`create-memorial-radio-wrapper w-100 ${relation === 'Sister' && 'selected'}`} onClick={(() => handleSetRelationShip('Sister', -164, 0, 'left', 'right'))}>
                                                <div className={`create-memorial-radio ${relation === 'Sister' && 'selected'}`}>
                                                    <div className="radio-selected"></div>
                                                </div>
                                                <p>Sister</p>
                                            </div>
                                            
                                            <div className={`create-memorial-radio-wrapper w-100 ${relation === 'Son' && 'selected'}`} onClick={(() => handleSetRelationShip('Son', 0, 250 ,'bottom', 'top'))}>
                                                <div className={`create-memorial-radio ${relation === 'Son' && 'selected'}`}>
                                                    <div className="radio-selected"></div>
                                                </div>
                                                <p>Son</p>
                                            </div>

                                            <div className={`create-memorial-radio-wrapper w-100 ${relation === 'Daughter' && 'selected'}`} onClick={(() => handleSetRelationShip('Daughter', 0, 250, 'bottom', 'top'))}>
                                                <div className={`create-memorial-radio ${relation === 'Daughter' && 'selected'}`}>
                                                    <div className="radio-selected"></div>
                                                </div>
                                                <p>Daughter</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {/* 1: DoB */}
                                <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep > 1 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>

                                    <p className="modal-heading mb-4">When were they born?</p>

                                    <div className="login-form">
                                        <div className="d-flex gap-3">
                                            <input ref={refBirthDateDay} id="birthDateDay" name="birthDateDay" type="text" maxLength={2} onChange={handleFormChangeDate} className="form-control" placeholder="DD" style={{border: invalidBirthDay ? invalidBorder : ""}} />
                                            <input ref={refBirthDateMonth} id="birthDateMonth" name="birthDateMonth" type="text" maxLength={2} onChange={handleFormChangeDate} className="form-control" placeholder="MM" style={{border: invalidBirthMonth ? invalidBorder : ""}} />
                                            <input ref={refBirthDateYear} id="birthDateYear" name="birthDateYear" type="text" maxLength={4} onChange={handleFormChangeDate} className="form-control" placeholder="YYYY" style={{border: invalidBirthYear ? invalidBorder : ""}} />
                                        </div>

                                        {invalidBirthDate &&
                                            <div className="invalid-form-entry animate__animated animate__zoomIn">Please enter a valid birth date</div>
                                        }
                                    </div>

                                </div>

                                {/* 2: DoD */}
                                <div className={`form-screen form-content form-content-${formStep === 2 ? 'current' : (formStep > 2 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                                    
                                    <p className="modal-heading mb-4">When did they pass away?</p>

                                    <div className="login-form">
                                        <div className="d-flex gap-3">
                                            <input ref={refDeathDateDay}  id="deathDateDay" name="deathDateDay" type="text" onChange={handleFormChangeDate} className="form-control" placeholder="DD" style={{border: invalidDeathDay ? invalidBorder : ""}} />
                                            <input ref={refDeathDateMonth}  id="deathDateMonth" name="deathDateMonth" type="text" onChange={handleFormChangeDate} className="form-control" placeholder="MM" style={{border: invalidDeathMonth ? invalidBorder : ""}} />
                                            <input ref={refDeathDateYear}  id="deathDateYear" name="deathDateYear" type="text" onChange={handleFormChangeDate} className="form-control" placeholder="YYYY" style={{border: invalidDeathYear ? invalidBorder : ""}} />
                                        </div>

                                        {invalidDeathDate &&
                                            <div className="invalid-form-entry animate__animated animate__zoomIn">Please enter a valid death date</div>
                                        }
                                    </div>

                                </div>

                                {/* 3: Name */}
                                <div className={`form-screen form-content form-content-${formStep === 3 ? 'current' : (formStep > 3 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>

                                    <p className="modal-heading mb-4">What was their name?</p>

                                    <div className="login-form">
                                        <div className="mb-4">
                                            <label htmlFor="firstName" className="form-label">First Name</label>
                                            <input id="firstName" name="firstName" type="text" onChange={handleFormChange} className="form-control" placeholder={props.fromFamilyTree ? props.selectedNode?.proxyFirstName : 'Enter their first name'} />
                                        </div>

                                        <div className="mb-4">
                                            <label htmlFor="lastName" className="form-label">Last Name</label>
                                            <input id="lastName" name="lastName" type="text" onChange={handleFormChange} className="form-control" placeholder={props.fromFamilyTree ? props.selectedNode?.proxyLastName : 'Enter their last name'} />
                                        </div>
                                        
                                        {(relation === 'Mother' || relation === 'Sister' || relation === 'Daughter') &&
                                            <div>
                                                <label htmlFor="lastName" className="form-label">Maiden name <span>(optional)</span></label>
                                                <input id="lastName" name="lastName" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter their maiden name" />
                                            </div>
                                        }
                                    </div>

                                </div>

                                <div className={`form-screen form-content form-content-${formStep === 4 ? 'current' : (formStep > 4 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>

                                    <p className="modal-heading mb-4">What were their religious beliefs?</p>

                                    <div className="login-form">
                                        <div className="d-flex flex-column">
                                            <select id="religiousBeliefId" name="religiousBeliefId" className="form-select" onChange={handleFormChange}>
                                                <option value="" className="select-option" selected disabled>Please Select</option>
                                                {religions}
                                            </select>

                                            {formState.religiousBeliefId === jewishReligionId &&
                                                <div className="d-flex flex-column gap-3 mt-4">
                                                    <p>Did they pass away after sunset?</p>

                                                    <div className={`create-memorial-radio-wrapper ${isBeforeSunset && 'selected'}`} onClick={() => handleBeforeSunset(true)}>
                                                        <div className={`create-memorial-radio ${isBeforeSunset && 'selected'}`}>
                                                            <div className="radio-selected"></div>
                                                        </div>
                                                        <p>Yes</p>
                                                    </div>

                                                    <div className={`create-memorial-radio-wrapper ${!isBeforeSunset && 'selected'}`} onClick={() => handleBeforeSunset(false)}>
                                                        <div className={`create-memorial-radio ${!isBeforeSunset && 'selected'}`}>
                                                            <div className="radio-selected"></div>
                                                        </div>
                                                        <p>No</p>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                                <button type="button" className="btn fl-btn-large w-100" onClick={handleClickNext}>Next</button>
                        </Modal.Footer>

                        {/* {isLoaded && formStep === 5 ?
                            <>
                                <div className="full-screen-div animate__animated animate__slideInRight animate__fast">

                                    <GoogleMap 
                                        mapContainerStyle={containerStyle}
                                        center={userLocation || center}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                        // onClick={handleMapClick}
                                        options={{disableDefaultUI: true,}}
                                    >
                                        {markerPosition?.lat !== 0 && markerPosition?.lng !== 0 && (
                                            <Marker position={markerPosition} />
                                        )}
                                    </GoogleMap>

                                </div>

                                <div className={`map-search ${searchOpen && "active"} pt-0 animate__animated animate__slideInUp ${modalSuccessMenu ? "animate__slideOutDown" : "animate__delay-1s"}`}>
                                    <div className="d-flex justify-content-center align-items-center" onClick={handleSearchOpen}>
                                        <div className="modal-account-drawer-close"></div>
                                    </div>

                                    <p className="fl-txt-title mb-3">Where were they laid to rest?</p>

                                    <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleLocationSelect}>
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div key={getSuggestionItemProps.name}>
                                            <input {...getInputProps({ placeholder: 'Search cemetery or location', className: 'location-search-input',})} onClick={handleSearchInput} />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div className="autocomplete-results">Loading...</div>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                    
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                    ? { cursor: 'pointer' }
                                                    : {  };

                                                    return (
                                                        <div {...getSuggestionItemProps(suggestion, {className,style})}>
                                                            <div className="d-flex justify-content-between gap-2 py-1">
                                                                <img src="/images/fl-map-pin-24.svg"  />
                                                                <span className="autocomplete-results w-100" onClick={handleSearchSelect} style={{overflow: "hidden"}}>
                                                                    <b>{suggestion.formattedSuggestion.mainText}</b>
                                                                    <br/>
                                                                    <span style={{whiteSpace: "nowrap"}}>{suggestion.formattedSuggestion.secondaryText}</span>
                                                                </span>
                                                                <img src="/images/arrow-up-left.svg" />
                                                            </div>
                                                            <hr className="autocomplete-hr" />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        )}
                                    </PlacesAutocomplete>

                                    {searchSelected && <button id="submitBtn" type="button" className="btn fl-btn-large w-100 animate__animated animate__fadeInUp animate__faster mb-2" onClick={handlePost}>Done</button>}
                                    {!searchOpen &&
                                        <div className="d-flex justify-content-center">
                                            <button className="autocomplete-results btn-location-skip animate__animated animate__fadeInUp animate__faster" onClick={handlePostSkipLocal}>Skip</button>
                                        </div>
                                    }
                                </div>
                            </>
                            : <></>
                        } */}
                    </>
                }

                {modalSuccess && 
                    <div className="modal-success-div animate__animated animate__slideInRight">
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center" style={{height: "85%"}}>
                            <img src="/images/creating-memorial.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>Creating Memorial Page</p>
                                <p>May they rest in peace.</p>
                            </div>
                        </div>
                    </div>
                }

            </Modal>
        </>
    );
}