import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "animate.css"

type ModalProps = {
    relativeTo: string;
    handleOpen: boolean;
    posX: number;
    posY: number;
    closeModal: () => void;
    handleSelectRelative: (relative: string, addPosX: number, addPosY: number, handleFrom: string, handleTo: string) => void;
}

export const AddRelativePopup: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Modal states
    const [show, setShow] = useState(false);
    const [popupAnimation, setPopupAnimation] = useState<string>('animate__animated animate__slideInUp');

    useEffect(() => {
        setShow(props.handleOpen);
        console.log(props.posX);
    }, [props.handleOpen]);

    // On modal show
    const handleShow = () => {
        setShow(true);
        setPopupAnimation('animate__animated animate__slideInUp');
    }

    // On modal hide
    const handleClose = () => {
        setPopupAnimation('animate__animated animate__slideOutDown');
        setTimeout(() => {
            props.closeModal();
            setPopupAnimation('animate__animated animate__slideInUp');
        }, 1000);
    }

    // Select relative
    const handleSelectRelative = (relative: string, addPosX: number, addPosY: number, handleFrom: string, handleTo: string) => {
        props.handleSelectRelative(relative, addPosX, addPosY, handleFrom, handleTo);
        handleClose();

        console.log(props.posX)
    }

    return (
        <>
            <Modal className="modal-add-relative" show={show} animation={false} backdrop onHide={handleClose}>
                <Modal.Body>
                    <div className={`add-relative-popup ${popupAnimation}`}>
                        <div className="popup-close" onClick={handleClose}><div /></div>

                        <p className="add-relative-txt">Add a relative to <span>{props.relativeTo}</span></p>

                        <div className="select-relation">
                            <div className="select-relative-row">
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Father', -82, -250, 'top', 'bottom')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Father
                                </div>
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Mother', 82, -250, 'top', 'bottom')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Mother
                                </div>
                            </div>

                            <div className="select-relative-row">
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Brother', -164, 0, 'left', 'right')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Brother
                                </div>
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Sister', -164, 0, 'left', 'right')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Sister
                                </div>
                            </div>

                            <div className="select-relative-row">
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Son', 0, 250 ,'bottom', 'top')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Son
                                </div>
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Daughter', 0, 250, 'bottom', 'top')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Daughter
                                </div>
                            </div>

                            <div className="select-relative-row">
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Partner', 164, 0, 'right', 'left')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Partner
                                </div>
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Pet', 0, -250 ,'bottom', 'top')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Pet
                                    <img src="images/dog-paw-icon.svg" className="btn-end" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}