import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "animate.css"
import { ASSET_URL, debug, deleteRecord, fetchRecords, postFormData } from "../../utils/AppUtil";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { PageResultType, MemorialType, DetailedNode, familyTreeUpdateType, } from "../AppType";

type ModalProps = {
    selectedNode: familyTreeUpdateType;
    showModal: boolean;
    closeModal: () => void;
}

export const EditRelationship: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [selected, setSelected] = useState<boolean>(false);

    // Modal states
    const [show, setShow] = useState(false);

    const [memorials, setMemorials] = useState<React.ReactElement[]>();
    const [selectedMemorialId, setSelectedMemorialId] = useState<string | null>(null);

    const formData: familyTreeUpdateType = {
        treeNodeId: '',
        userId: '',
        memorialId: '',
        relationship: '',
        data: {
            posX: 0,
            posY: 0,
            connections: [{
                handleTo: '',
                handleFrom: '',
                fromTreeNodeId: ''
            }]
        }
    }

    const [formState, setFormState] = useState<familyTreeUpdateType>(formData);

    const handleClose = () => {
        props.closeModal();
    }

    return (
        <>

            {/* <div className="selected-tile-link" onClick={handleShow}>
                <p>Link an existing memorial</p>
                <img src="/images/fl-icon-connect-memorial.svg" />
            </div> */}

            <Modal show={props.showModal} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    <p className="modal-title">Link a Memorial</p>
                    
                    <div className={`btn btn-modal-close`} onClick={handleClose} >
                        <img src="/images/modal-close-x.svg"/>
                    </div>
                </Modal.Header>

                <Modal.Body className="p-3">
                    <div className="input-group">
                        <input className="family-tree-search-input" type="text" placeholder="Search all memorials" />
                        <div className="search-icon-wrapper">
                            <img src="/images/fl-icon-search.svg" />
                        </div>
                    </div>

                    <p className="fl-txt-home-subheading mt-4 mb-3">Your Memorials</p>

                    <div className="d-flex flex-column gap-3">
                        {memorials}
                    </div>                    
                </Modal.Body>
                
                <Modal.Footer>
                    <button className="btn btn-modal-footer">Continue</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}