import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { debug, deleteRecord, fetchRecords, postFormData } from "../../utils/AppUtil";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import { familyTreeGetType, familyTreeNode, familyTreePostType, familyTreeUpdateType, MemorialType } from "../AppType";
import { AddRelativePopup } from "./AddRelativePopup";
import { DeceasedPlaceholderPopup } from "./DeceasedPlaceholderPopup";
import { LivingPlaceholderPopup } from "./LivingPlaceholderPopup";
import { ReactFlow, Background, Controls, applyEdgeChanges, applyNodeChanges, Position, BackgroundVariant, Edge, NodeChange, addEdge } from '@xyflow/react';
import { TreeNode } from "./TreeNode";
import { DetailedNode } from "./DetailedNode";
import { DefaultNode } from "./DefaultNode";
import { LinkMemorial } from "./LinkMemorial";
import '@xyflow/react/dist/style.css';
import "animate.css"
import { ModalCreateMemorial } from "../Modals/ModalCreateMemorial";
import { EditRelationship } from "./EditRelationship";

type AddRelativeType = {
    name: string,
    posX?: number,
    posY?: number,
}

type ModalProps = {
    handleViewMemorial: () => void;
    userGender: string;
    userPicture: string;
    treeImages: (images: string[]) => void;
}

export const ModalFamilyTree: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Modal handling states
    const [show, setShow] = useState(false);
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [modalSuccessAnimation, setModalSuccessAnimation] = useState<string>('animate__animated animate__slideInRight');

    // Popup display states to trigger after adding a proxy node
    const [deceasedRelativePopupOpen, setDeceasedRelativePopupOpen] = useState<boolean>(false);
    const [livingRelativePopupOpen, setLivingRelativePopupOpen] = useState<boolean>(false);

    const [addRelativePopup, setAddRelativePopup] = useState<boolean>(false);
    
    const [addRelativeData, setAddRelativeData] = useState<AddRelativeType>()

    // Detailed node view states
    const [detailedNodeActive, setDetailedNodeActive] = useState<boolean>(false);
    const [detailedNodeType, setDetailedNodeType] = useState<string>("");
    const [detailedNodeMemorial, setDetailedNodeMemorial] = useState<MemorialType>();

    const [homepageTreeImages, setHomepageTreeImages] = useState<string[]>(['']);

    const [edgeType, setEdgeType] = useState<string>("step");

    const detailedNodeDefault: familyTreeUpdateType = {
        treeNodeId: "",
        userId: "",
        memorialId: "",
        relationship: "",
        data: {
            posX: 0,
            posY: 0,
            connections: [{
                handleTo: "",
                handleFrom: "",
                fromTreeNodeId: ""
            }]
        }
    }
    const [detailedNodeData, setDetailedNodeData] = useState<familyTreeUpdateType>(detailedNodeDefault);   
    
    const [selectedRelative, setSelectedRelative] = useState<string>('');
    const [hasPassed, setHasPassed] = useState<boolean>(false);

    const [formStep, setFormStep] = useState<number>(0);
    const [refreshCounter, setRefreshCounter] = useState<number>(0);
    const [draggedNodePositions, setDraggedNodePositions] = useState<Record<string, { x: number, y: number }>>({});

    const [linkMemorialModalOpen, setLinkMemorialModalOpen] = useState<boolean>(false);
    const [createMemorialModalOpen, setCreateMemorialModalOpen] = useState<boolean>(false);
    const [editRelationshipModalOpen, setEditRelationshipModalOpen] = useState<boolean>(false);

    // Ref to store the initial Y position of the node when it's grabbed
    const initialYRef = useRef<number>(0);

    // formData for posting a new proxy node to the DB
    const formData: familyTreePostType = {
        userId: "",
        memorialId: "",
        proxyFirstName: "",
        proxyLastName: "",
        proxyType: "USER",
        relationship: "",
        data: {
            posX: 0,
            posY: 0,
            connections: [{
                handleTo: "",
                handleFrom: "",
                fromTreeNodeId: ""
            }]
        }
    }
    const [formState, setFormState] = useState<familyTreePostType>(formData);



    // ==================
    // CREATING NEW NODES
    // ==================
    // #region

        const handleAddRelativePopup = (firstName: string, posX: number, posY: number, fromTreeNodeId: string) => {
            setAddRelativePopup(true);

            console.log('firstName:', firstName);
            console.log('posX:', posX);
            console.log('posY:', posY);
            console.log('fromTreeNodeId:', fromTreeNodeId);

            setAddRelativeData((addRelativeData) => ({
                ...addRelativeData,
                name: firstName,
                posX: posX,
                posY: posY,
            }));

            setFormState((formState) => ({
                ...formState,
                data: {
                    ...formState.data,
                    fromTreeNodeId: fromTreeNodeId
                }
            }));
        }

        const handleDetailedNode = (
            treeNodeId: string,
            userId: string,
            memorial: MemorialType,
            firstName: string,
            lastName: string,
            proxyType: string,
            relationship: string,
            posX: number,
            posY: number,
            handleTo: string,
            handleFrom: string,
            fromTreeNodeId: string,
            displayType: string
        ) => {

            setDetailedNodeActive(true);
            setDetailedNodeType(displayType);
            setDetailedNodeMemorial(memorial);

            setDetailedNodeData(detailedNodeData => ({
                ...detailedNodeData,
                treeNodeId: treeNodeId,
                userId: userId,
                memorialId: memorial?.memorialId,
                proxyFirstName: firstName,
                proxyLastName: lastName,
                proxyType: proxyType,
                relationship: relationship,
                data: {
                    posX: posX,
                    posY: posY,
                    connections: [{
                        handleTo: handleTo,
                        handleFrom: handleFrom,
                        fromTreeNodeId: fromTreeNodeId
                    }]
                }
            }));
        }

        const handleAddRelative = (relation: string, addPosX: number, addPosY: number, handleFrom: string, handleTo: string) => {
            setSelectedRelative(relation);

            console.log('relation:', relation);
            console.log('addPosY:', addPosY);
            console.log('handleFrom:', handleFrom);
            console.log('handleTo:', handleTo);

            console.log('RELATIVE DATAL:', addRelativeData);

            setFormState((formState) => ({
                ...formState, 
                relationship: relation,
                data: {
                    ...formState.data,
                    handleFrom: handleFrom,
                    handleTo: handleTo
                }
            }));

            // CLEAN UP HERE start

            const newPosY: number = addRelativeData?.posY as number + addPosY;
            const newPosX: number = addRelativeData?.posX as number + addPosX;

            console.log(newPosX);
            console.log(newPosY);
           

            const addRelativePosX = addRelativeData?.posX;
            const addRelativePosY = addRelativeData?.posY;
            // console.log('addRelativePosX:', addRelativePosX);
            // console.log('addRelativePosY:', addRelativePosY);
            // let newPosY: number;

            // if (addRelativePosY) newPosY = addRelativePosY + addPosY;
            
            setFormState((formState) => ({
                ...formState, 
                data: {
                    ...formState.data,
                    posX: newPosX,
                    posY: newPosY
                }
            }));

            // CLEAN UP HERE end

            setTimeout(() => {
                setFormStep(1);
            }, 1000);
        }

        const handleAddFatherMother = (id: string, posX: number, posY: number) => {

            let newRelative: string;
            if (id === 'addFatherNode') {
                newRelative = 'Father'
            } else {
                newRelative = 'Mother'
            }

            setSelectedRelative(newRelative);

            setAddRelativeData((addRelativeData) => ({
                ...addRelativeData,
                name: commonData.firstName
            }));

            setFormState((formState) => ({
                ...formState, 
                relationship: newRelative,
                data: {
                    ...formState.data,
                    handleFrom: 'top',
                    handleTo: 'bottom' ,
                    posX: posX,
                    posY: posY,
                    fromTreeNodeId: 'originNode'
                }
            }));

            setFormStep(1);
        }
    
    // #endregion



    // =====================
    // INITIAL NODES & EDGES
    // =====================
    // #region

        const nodeTypes = {node: TreeNode, defaultNode: DefaultNode};

        const initialNodes = [
            {
                id: 'originNode',
                position: { x: 0, y: 0 },
                type: 'node',
                draggable: false,
                data: {
                    id: 'originNode',
                    position: { x: 0, y: 0 },
                    fromTreeNodeId: '',
                    handleFrom: '',

                    userId: commonData.userId,
                    memorialId: "",

                    displayType: 'origin',
                    firstName: commonData.firstName,
                    lastName: commonData.lastName,
                    relation: 'You',
                    gender: props.userGender,
                    pictureId: props.userPicture,

                    targetHandle: Position.Bottom,
                    sourceHandleTop: Position.Top,
                    sourceHandleRight: Position.Right,
                    sourceHandleBottom: Position.Bottom,
                    sourceHandleLeft: Position.Left,

                    targetHandleId: 'target-originNode',
                    sourceHandleTopId: 'source-top-originNode',
                    sourceHandleRightId: 'source-right-originNode',
                    sourceHandleBottomId: 'source-bottom-originNode',
                    sourceHandleLeftId: 'source-left-originNode',

                    extensionPos: 'none',
                    handleExtentionClick: handleAddRelativePopup,
                    handleNodeClick: handleDetailedNode
                }
            },
            {
                id: 'addFatherNode',
                position: { x: -82, y: -250 },
                type: 'defaultNode',
                draggable: false,
                data: {
                    id: 'addFatherNode',
                    position: { x: -82, y: -250 },
                    fromTreeNodeId: 'originNode',
                    handleFrom: 'top',

                    userId: "",
                    memorialId: "",

                    displayType: 'initial',
                    firstName: 'Add',
                    lastName: 'Father',
                    relation: '',

                    targetHandle: Position.Bottom,
                    sourceHandleTop: Position.Top,
                    sourceHandleRight: Position.Right,
                    sourceHandleBottom: Position.Bottom,
                    sourceHandleLeft: Position.Left,

                    targetHandleId: 'target-addFatherNode',
                    sourceHandleTopId: 'source-top-addFatherNode',
                    sourceHandleRightId: 'source-right-addFatherNode',
                    sourceHandleBottomId: 'source-bottom-addFatherNode',
                    sourceHandleLeftId: 'source-left-addFatherNode',

                    extensionPos: 'none',
                    handleExtentionClick: handleAddRelativePopup,
                    handleNodeClick: handleAddFatherMother
                }
            },
            {
                id: 'addMotherNode',
                position: { x: 82, y: -250 },
                type: 'defaultNode',
                draggable: false,
                data: {
                    id: 'addMotherNode',
                    position: { x: 82, y: -250 },
                    fromTreeNodeId: 'originNode',
                    handleFrom: 'top',

                    userId: "",
                    memorialId: "",

                    displayType: 'initial',
                    firstName: 'Add',
                    lastName: 'Mother',
                    relation: '',

                    targetHandle: Position.Bottom,
                    sourceHandleTop: Position.Top,
                    sourceHandleRight: Position.Right,
                    sourceHandleBottom: Position.Bottom,
                    sourceHandleLeft: Position.Left,

                    targetHandleId: 'target-addMotherNode',
                    sourceHandleTopId: 'source-top-addMotherNode',
                    sourceHandleRightId: 'source-right-addMotherNode',
                    sourceHandleBottomId: 'source-bottom-addMotherNode',
                    sourceHandleLeftId: 'source-left-addMotherNode',

                    extensionPos: 'up',
                    handleExtentionClick: handleAddRelativePopup,
                    handleNodeClick: handleAddFatherMother
                }
            },
        ];

        const initialEdges = [
            {
                id: 'originNode->addFatherNode',
                source: 'originNode',
                target: 'addFatherNode',
                sourceHandle: 'source-top-originNode',
                targetHandle: 'target-addFatherNode',
                type: 'step',
                style: { stroke: '#1A323C', strokeWidth: 2 }
            },
            {
                
                id: 'originNode->addMotherNode',
                source: 'originNode',
                target: 'addMotherNode',
                sourceHandle: 'source-top-originNode',
                targetHandle: 'target-addMotherNode',
                type: 'step',
                style: { stroke: '#1A323C', strokeWidth: 2 }
            }
        ];

        // const [nodes, setNodes] = useState<familyTreeNode[]>(initialNodes);

        const [dynamicNodes] = useState<familyTreeNode[]>([]);
        const [nodes, setNodes] = useState<familyTreeNode[]>([...initialNodes]);

        const [dynamicEdges] = useState<Edge[]>([]);
        const [edges, setEdges] = useState<Edge[]>([...initialEdges]);
        
        // const [edges, setEdges] = useState<Edge[]>(initialEdges);

    // #endregion



    // ===============================
    // USEEFFECTS, ZOOMING & SCROLLING
    // ===============================
    // #region

        //Get family tree data
        useEffect(() => {

            fetchRecords(commonData.token, 'user/loggedIn/familyTree', processFamilyTreeData)
            .catch((error) => {
                debug('fetchRecords error: ' + error, 'FamilyTree');
            });
        }, [refreshCounter, detailedNodeData]);

        useEffect(() => {
            // Combine initial nodes with dynamic nodes
            setNodes([...initialNodes, ...dynamicNodes]);
        }, [dynamicNodes]); // Recalculate whenever dynamic nodes change

        // Disable vertical scrolling on body when modal is open
        useEffect(() => {
            const preventScroll = (e: TouchEvent) => e.preventDefault();
        
            if (show) {
                document.body.style.position = 'fixed';
                window.addEventListener('touchmove', preventScroll, { passive: false });
            } else {
                document.body.style.position = '';
                window.removeEventListener('touchmove', preventScroll);
            }
        
            return () => {
                document.body.style.position = '';
                window.removeEventListener('touchmove', preventScroll);
            };
        }, [show]);

        // Disable DOM zooming to allow pinch zooming
        const deactivateZoomDiv = document.getElementById('deactivateZoom');
        deactivateZoomDiv?.addEventListener('touchmove', function(event) {
            const touchEvent = event as TouchEvent & { scale: number };
            if (touchEvent.scale !== 1) {
                event.preventDefault();
            }
        }, {passive: false});

    // #endregion

    

    // =====================================
    // PROCESS DATA FUNCTIONS (NODES & EDGES)
    // =====================================
    // #region


        const processFamilyTreeData = (responseJson: familyTreeGetType[]) => {
            setNodes((prevNodes) => {
                // Build initial updated nodes
                const updatedNodes = [
                    ...initialNodes,
                    ...buildFamilyTreeNodes(responseJson).filter(
                        (node) => !initialNodes.some((initNode) => initNode.id === node.id)
                    )
                ];
        
                // Get edges for processing
                const currentEdges = [
                    ...initialEdges,
                    ...buildFamilyTreeEdges(responseJson).filter(
                        (edge) => !initialEdges.some((initEdge) => initEdge.id === edge.id)
                    )
                ];
        
                // Use updateNodes to remove unnecessary 'addMotherNode' or 'addFatherNode'
                return updateNodes(updatedNodes, currentEdges);
            });
        
            setEdges((prevEdges) => {
                // Update edges as usual
                return [
                    ...initialEdges,
                    ...buildFamilyTreeEdges(responseJson).filter(
                        (edge) => !initialEdges.some((initEdge) => initEdge.id === edge.id)
                    )
                ];
            });
        };
        

        // Build tree nodes
        const buildFamilyTreeNodes = (response: familyTreeGetType[]): any[] => {
            let key: number = 0;

            const result: any[] = [];
            const imageIdArray: string[] = [];

            response.forEach((node: familyTreeGetType) => {

                let displayType: string = '';
                let firstName: string = '';
                let lastName: string = '';
                let picture: string= '';
                
                if (node.user) {
                    displayType = 'user';
                    firstName = node.user.firstName;
                    lastName = node.user.lastName;
                    picture = node.user.profilePicture.pictureId;
                    if (picture !== '' && picture !== null) imageIdArray.unshift(picture);
                    else imageIdArray.push('proxy');

                } else if (node.memorial) {
                    displayType = 'memorial';
                    firstName = node.memorial.firstName;
                    lastName = node.memorial.lastName;
                    picture = node.memorial.memorialPictureId;
                    if (picture !== '' && picture !== null)  imageIdArray.unshift(picture);
                    else imageIdArray.push('proxy');

                } else {
                    firstName = node.proxyFirstName;
                    lastName = node.proxyLastName;
                    imageIdArray.push('proxy');

                    if (node.proxyType === 'USER') displayType = 'proxy-user';
                    else displayType = 'proxy-memorial';
                }

                result.push(
                    {
                        key: key,
                        id: node.treeNodeId,
                        position: { x: node.data?.posX, y: node.data?.posY },
                        type: 'node',
                        draggable: true,
                        data: {
                            id: node.treeNodeId,
                            position: { x: node.data?.posX, y: node.data?.posY },   
                            fromTreeNodeId: node.data.fromTreeNodeId,
                            handleFrom: node.data.handleFrom,

                            // userId: node.user.userId ? node.user.userId : '',
                            memorial: node.memorial ? node.memorial : '',
                            proxyType: node.proxyType ? node.proxyType : '',

                            displayType: displayType,
                            firstName: firstName,
                            lastName: lastName,
                            relation: node.relationship,
                            pictureId: picture,

                            targetHandle: node.data?.handleTo,
                            sourceHandleTop: Position.Top,
                            sourceHandleRight: Position.Right,
                            sourceHandleBottom: Position.Bottom,
                            sourceHandleLeft: Position.Left,

                            targetHandleId: `target-${node.treeNodeId}`,
                            sourceHandleTopId: `source-top-${node.treeNodeId}`,
                            sourceHandleRightId: `source-right-${node.treeNodeId}`,
                            sourceHandleBottomId: `source-bottom-${node.treeNodeId}`,
                            sourceHandleLeftId: `source-left-${node.treeNodeId}`,

                            extensionPos: (node.data?.posY === 0 ? 'none' : (node.data?.posY < 0 ? 'up' : 'down')),
                            handleExtentionClick: handleAddRelativePopup,
                            handleNodeClick: handleDetailedNode,
                        }
                    }
                );

                key ++;
            });

            props.treeImages(imageIdArray);
            return result;
        }

        // Build node edges
        const buildFamilyTreeEdges = (response: familyTreeGetType[]): any[] => {
            let key: number =   0;
            const result: any[] = [];

            response.forEach((edge: familyTreeGetType) => {
                result.push({
                    key: key,
                    id: `${key}->${edge.data?.fromTreeNodeId}->${edge.treeNodeId}`,
                    source: edge.data?.fromTreeNodeId,
                    target: edge.treeNodeId,
                    sourceHandle: `source-${edge.data?.handleFrom}-${edge.data?.fromTreeNodeId}`,
                    targetHandle: `target-${edge.treeNodeId}`,
                    type: edgeType,
                    style: { stroke: '#1A323C', strokeWidth: 2 }
                });

                key ++;
            });

            return result;
        }

        const updateNodes = (nodes: familyTreeNode[], edges: Edge[]): familyTreeNode[] => {
            // Find direct connections from 'originNode'
            const connectedNodes = edges
                .filter(edge => edge.source === 'originNode') // Edges originating from 'originNode'
                .map(edge => edge.target); // Target node IDs
        
            // Check if any connected node has 'relation' of 'Mother' or 'Father'
            const hasMother = nodes.some(node => connectedNodes.includes(node.id) && node.data.relation === 'Mother');
            const hasFather = nodes.some(node => connectedNodes.includes(node.id) && node.data.relation === 'Father');
        
            // Filter out 'addMotherNode' or 'addFatherNode' if conditions are met
            return nodes.filter(node => {
                if (node.id === 'addMotherNode' && hasMother) {
                    return false; // Remove 'addMotherNode'
                }
                if (node.id === 'addFatherNode' && hasFather) {
                    return false; // Remove 'addFatherNode'
                }
                return true; // Keep all other nodes
            });
        }

    // #endregion



    // ============================
    // REACT FLOW HANDLER FUNCTIONS
    // ============================
    // #region

        const onNodesChange = useCallback(
            (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
            [setNodes],
        );

        const onEdgesChange = useCallback(
            (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
            [setNodes],
        );

        const onConnect = useCallback(
            (params: any) => setEdges((eds) => addEdge(params, eds)),
            [setNodes],
        );

        // Handle the start of dragging
        const handleNodeDragStart = useCallback((event: React.MouseEvent, node: familyTreeNode) => {

            // Capture the Y position of the node when dragging starts and store it in the ref
            initialYRef.current = node.position.y;
            console.log("Node grabbed. Y position captured:", node.position.y);
        }, []);

        // Set nodes when change detected
        const handleNodesChange = useCallback((changes: NodeChange[]) => {
            setNodes((nds) =>
            applyNodeChanges(
                changes.map((change) => {
                    if (change.type === 'position') {
                        const node = nds.find((n) => n.id === change.id);
                        if (node) {
                        return {
                            ...change,
                            type: "position", // Set to the literal "position" as required
                            position: { x: change.position?.x ?? node.position.x, y: initialYRef.current },
                        };
                        }
                    }

                    return change;
                }),
                nds
            ) as familyTreeNode[]
            );
            
        }, []);

        // Node dragging OLD
        const handleNodeDragStopOLD = useCallback((event: React.MouseEvent, node: familyTreeNode) => {
            
            // Capture the new X position and keep the Y position unchanged
            const newPosition = {
                x: node.position.x, // update X position
                y: initialYRef.current, // keep Y position the same
            };

            // Log the updated position (only X should change)
            console.log('New X position:', newPosition.x);
            console.log('Y position is locked:', newPosition.y);

            // Update state with the new position (keeping Y fixed)
            setNodes((prevNodes) =>
                prevNodes.map((n) =>
                    n.id === node.id ? { ...n, position: newPosition } : n
                )
            );

            const formDataReposition = {
                posX: Math.ceil(node.position.x),
                posY: initialYRef.current,
            }

            postFormData(
                commonData.token,
                `user/loggedIn/familyTree/${node.id}/data`,
                formDataReposition,
                (response: any) => {
                    console.log("data sent! ----->", response);
                },
                (error: any) => { // error
                    console.log("oops! ----->", error);
                }
            );
            
        }, []);

        // Node dragging and edge updates during drag
        const handleNodeDrag = useCallback((event: React.MouseEvent, node: familyTreeNode) => {

            // Restrict Y-axis position during the drag (keep it fixed)
            const updatedPosition = {
                x: Math.ceil(node.position.x / 41) * 41,  // Update X-axis
                y: initialYRef.current // Keep Y-axis fixed
            };

            // Update draggedNodePositions immediately during the drag
            setDraggedNodePositions(prev => ({
                ...prev,
                [node.id]: updatedPosition // Real-time update for node position (X-axis only)
            }));

        }, [edges, nodes]);

        // Node draging release (finalize position)
        const handleNodeDragStop = useCallback((event: React.MouseEvent, node: familyTreeNode) => {
            const connectedEdge = edges.find(edge => (edge.target === node.id));

            let newHandleTo: string = node.data.targetHandle;

            if (connectedEdge && (node.data.targetHandle === 'left' || node.data.targetHandle == 'right')) {
                const connectedNodeId = connectedEdge.source === node.id ? connectedEdge.target : connectedEdge.source;
                const connectedNode = nodes.find(n => n.id === connectedNodeId);

                if (connectedNode) {
                    const isLeft = node.position.x < connectedNode.position.x;
                    const isRight = node.position.x > connectedNode.position.x;

                    if (isLeft) newHandleTo = 'right';
                    else if (isRight) newHandleTo = 'left';

                    console.log('Updated Handle Position:', newHandleTo);
                }
            }

            const formDataReposition = {
                posX: Math.ceil(node.position.x),
                posY: initialYRef.current,
                handleTo: newHandleTo
            };

            postFormData(
                commonData.token,
                `user/loggedIn/familyTree/${node.id}/data`,
                formDataReposition,
                (response: any) => console.log("Data sent!", response),
                (error: any) => console.log("Oops! Error:", error)
            );

            setRefreshCounter(c => c + 1);
        }, [edges, nodes]);

        const handleDeleteNode = (id: string) => {
            deleteRecord(
                commonData.token,
                `user/loggedIn/familyTree/${id}`,
                (response: any) => {
                    console.log("Node deleted");
                },
                (error: any) => { // error
    
                }
            );
        }

    // #endregion



    // ========================
    // MODAL HANDLING FUNCTIONS
    // ========================
    // #region

        const handleShow = () => {
            setShow(true);
            setFormStep(0);
        }

        const handleClose = () => {
            setShow(false);
            setFormStep(0);
        }

        const handleOpenModal = (modal: string) => {
            if (modal === 'linkMemorial') setLinkMemorialModalOpen(true);
            else if (modal === 'createMemorial') setCreateMemorialModalOpen(true);
        }

        const handleCloseDetailedNode = () => {
            setDetailedNodeActive(false);

            // setRefreshCounter((prev) => prev + 1); // Force refresh
            
            fetchRecords(commonData.token, 'user/loggedIn/familyTree', processFamilyTreeData)
            .then(() => {
                // setRefreshCounter((prev) => prev + 1); // Force refresh
            })
            .catch((error) => {
                debug('fetchRecords error: ' + error, 'FamilyTree');
            });
        }

    // #endregion


    
    // =======================
    // FORM HANDLING FUNCTIONS
    // =======================
    // #region

        const handleFormChange = (e: React.ChangeEvent<any>) => {
            if (e.target.type === "text") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: e.target.value
                }));
            }
        }

        const handleClickBack = () => {
            if (formStep !== 0) {
                setFormStep(formStep - 1);
            } else {
                handleClose();
            }        
        }

        const handleHasPassed = (passed: boolean) => {
            setHasPassed(passed);
    
            let proxyType: string = 'USER';
            if (passed) proxyType = 'MEMORIAL'
            
            setFormState((formState) => ({
                ...formState, 
                proxyType: proxyType
            }));

        }
    
        const handleConfirmRelative = () => {
            setModalSuccess(true);
    
            setTimeout(() => {
                setFormStep(0);
                setSelectedRelative('');
            }, 1000);
    
            setTimeout(() => {
                setModalSuccessAnimation('animate__animated animate__slideOutRight');
            }, 2000);
    
            setTimeout(() => {
                setModalSuccess(false);
                setModalSuccessAnimation('');
                setFormState(formData);

                if (hasPassed) setDeceasedRelativePopupOpen(true);
                else setLivingRelativePopupOpen(true);
                
            }, 3000);
        }

        const handleViewMemorial = () => {
            props.handleViewMemorial();
            handleClose();
        }

    // #endregion



    // =========
    // POST DATA
    // =========
    // #region

        const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
            e.preventDefault();

            console.log('FORMSTATE------------------- ', formState);
            console.log(formState.proxyFirstName)

            postFormData(
                commonData.token,
                `user/loggedIn/familyTree`,
                [formState],
                (response: any) => {
                    setDetailedNodeData(response[0]);

                    fetchRecords(commonData.token, 'user/loggedIn/familyTree', processFamilyTreeData)
                    .then(() => {
                        handleRefresh();
                        handleConfirmRelative();
                    })
                    .catch((error) => {
                        debug('fetchRecords error: ' + error, 'FamilyTree');
                    });
                },
                (error: any) => { // error

                }
            );

            setHasPassed(false);
        }

    // #endregion

    const handleRefresh = () => {
        setRefreshCounter(c => c+1);
        console.log(refreshCounter);
    }

    const handleToggleEdgeType = () => {
        if (edgeType === 'step') setEdgeType('simplebezier');
        else setEdgeType('step');
        handleRefresh();
    }

    return (
        <>
            <div className="btn fl-btn-dark w-100 mt-2" onClick={handleShow}>Start</div>

            <Modal className="modal-deactivate-scroll" show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    <div className={`btn-modal-back`} onClick={handleClickBack}>
                        <img src="/images/modal-back-chev.svg" />
                    </div>

                    <p className="modal-title" onClick={handleToggleEdgeType}>The {commonData.lastName} family tree</p>
                    
                    <div className={`btn btn-modal-close`} onClick={handleClose} >
                        <img src="/images/modal-close-x.svg"/>
                    </div>
                </Modal.Header>

                <Modal.Body className="p-0">
                    <div className={`family-tree-wrapper form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep > 0 ? 'prev' : 'next')} justify-content-center`}>

                        <ReactFlow
                            nodes={nodes.map(node => ({
                                ...node,
                                position: draggedNodePositions[node.id] || node.position // Use updated position from draggedNodePositions
                            }))}
                            edges={edges}
                            onNodesChange={handleNodesChange}
                            onEdgesChange={onEdgesChange}
                            onNodeDragStart={handleNodeDragStart} // Capture Y position when dragging starts
                            onNodeDragStop={handleNodeDragStop}
                            onNodeDrag={handleNodeDrag}  // Track node movement during drag
                            onConnect={onConnect}
                            nodeTypes={nodeTypes}
                            fitView
                        >
                            <Background variant={BackgroundVariant.Dots} />
                            {/* <Controls /> */}
                        </ReactFlow>

                    </div>

                    <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep > 1 ? 'prev' : 'next')} p-4`}>
                        
                        {selectedRelative !== '' &&
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Tell us about {addRelativeData?.name}'s {selectedRelative}</p>
                                </div>

                                <div className="login-form">
                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">First name</label>
                                        <input id="proxyFirstName" name="proxyFirstName" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter their first name" required />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">Last name</label>
                                        <input id="proxyLastName" name="proxyLastName" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter their last name" required />
                                    </div>

                                    <label htmlFor="email" className="form-label">Have they passed away?</label>
                                    <div className="d-flex gap-3">
                                        <div className="create-memorial-radio-wrapper d-flex align-items-center gap-3" onClick={() => handleHasPassed(true)}>
                                            <div className={`create-memorial-radio ${hasPassed && 'selected'}`}>
                                                <div className="radio-selected"></div>
                                            </div>
                                            <p>Yes</p>
                                        </div>

                                        <div className="create-memorial-radio-wrapper d-flex align-items-center gap-3" onClick={() => handleHasPassed(false)}>
                                            <div className={`create-memorial-radio ${!hasPassed && 'selected'}`}>
                                                <div className="radio-selected"></div>
                                            </div>
                                            <p>No</p>
                                        </div>
                                    </div>

                                    <button onClick={handlePost} className="btn fl-btn-modal-bottom button-absolute">Done</button>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>

                {modalSuccess && 
                    <div className={`modal-success-div ${modalSuccessAnimation}`} style={{zIndex: 999}}>
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center h-100">
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>{formState.proxyFirstName} has been added</p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                }

                <AddRelativePopup
                    handleSelectRelative={handleAddRelative}
                    posX={addRelativeData?.posX as number}
                    posY={addRelativeData?.posY as number}
                    relativeTo={addRelativeData?.name as string}
                    handleOpen={addRelativePopup}
                    closeModal={() => setAddRelativePopup(false)}
                />

                {detailedNodeData &&
                    <>
                        <DetailedNode
                            detailedNodeData={detailedNodeData}
                            id={detailedNodeData.treeNodeId}
                            memorial={detailedNodeMemorial}
                            firstName={detailedNodeData.proxyFirstName ? detailedNodeData.proxyFirstName : ''}
                            lastName={detailedNodeData.proxyLastName ? detailedNodeData.proxyLastName : ''}
                            relation={detailedNodeData.relationship}
                            posX={detailedNodeData.data.posX}
                            posY={detailedNodeData.data.posY}    
                            displayType={detailedNodeType}
                            handleOpen={detailedNodeActive}
                            handleExtentionClick={handleAddRelativePopup}
                            openMemorial={handleViewMemorial}
                            closeModal={handleCloseDetailedNode}
                            openModal={handleOpenModal}
                            deleteNode={handleDeleteNode}
                        
                        />
                        
                        <ModalCreateMemorial emptyState={false} fromFamilyTree={true} selectedNode={detailedNodeData} relation={detailedNodeData.relationship} showModal={createMemorialModalOpen} closeModal={() => {setCreateMemorialModalOpen(false); handleRefresh()}} link={""} />
                        <LinkMemorial selectedNode={detailedNodeData} showModal={linkMemorialModalOpen} closeModal={() => {setLinkMemorialModalOpen(false); handleRefresh()}} />
                        <EditRelationship selectedNode={detailedNodeData} showModal={editRelationshipModalOpen} closeModal={() => {setEditRelationshipModalOpen(false); handleRefresh()}} />
                    </>
                }
                            
                <DeceasedPlaceholderPopup relative={detailedNodeData?.proxyFirstName} showModal={deceasedRelativePopupOpen} openModal={handleOpenModal} />
                <LivingPlaceholderPopup relative={detailedNodeData?.proxyFirstName} showModal={livingRelativePopupOpen} />            

            </Modal>
        </>
    );
}