import { useTranslation } from "react-i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { ASSET_URL, debug, fetchRecords } from "../../utils/AppUtil";


export const FamilyTree: React.FC = () => {
    
    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;


    let memorialPictureSrc = "/images/tree-avatar-placeholder.svg";
    if (memorialData.memorialPictureId) {
        memorialPictureSrc = `${ASSET_URL}/public/picture/${memorialData.memorialPictureId}`;
    }

    // useEffect(() => {

    //     fetchRecords(commonData.token, `memorial/${memorialData.memorialId}/familyTree`, processTreeData)
    //     .catch((error) => {;
    //         debug('fetchRecords error: ' + error, 'family_tree');
    //     });

    // }, [memorialData]);

    // const processTreeData = (responseJson: MemoryType[]) => {
    //     setMemoriesState(buildMemoriesListJSX(responseJson));
    // }

    return (
        <>
            <div className="d-flex flex-column p-3">

                <p className="memorial-family-tree-section-label">{memorialData.firstName}’s Family</p>
            
                <div className="mt-2">
                    <div className="memorial-family-tree-card mb-3">
                        <div className="tree-tile-body">
                            <p className="tree-name">{memorialData.lastName} Family</p>
                            <div className="tree-images-container">
                                <div className="line faded" />
                                <div className="tree-images">
                                    <div className="tree-img-wrapper">
                                        <div className="line" />
                                        <img src='/images/account-user-avatar-borderless.svg' width={48} height={48} alt="" />
                                    </div>
                                    <div className="tree-img-wrapper">
                                        <div className="line" />
                                        <img src='/images/account-user-avatar-borderless.svg' width={48} height={48} alt="" />
                                    </div>
                                    <div className="tree-img-wrapper">
                                        <div className="line" />
                                         <div className="tree-no-image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="memorial-family-tree-card-btn">View</button>
                    </div>
                </div>              
            </div>
        </>
    );
}