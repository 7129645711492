import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { debug } from './utils/AppUtil';
import { MemorialType } from './components/AppType';

// Define types for your context data
interface MemorialContextData {
  memorialData: MemorialType;
  setMemorialData: React.Dispatch<React.SetStateAction<MemorialType>>;
}

// Create a context object
export const MemorialContext = createContext<MemorialContextData | undefined>(undefined);

// Create a provider component
export const MemorialProvider: React.FC<{children: ReactNode}> = (props) => {
  	const memorialDefault: MemorialType = {
		memorialId: '',
		firstName: '',
		lastName: '',
		familyStory: '',
		birthDate: '',
		birthDateDisplay: '',
		birthYear: 0,
		deathDate: '',
		deathDateDisplay: '',
		deathYear: 0,
		memorialPictureId: '',
		coverPictureId: '',
		creator: {
			userId: '',
			firstName: '',
			lastName: '',
			email: '',
			profilePicture: {
                pictureId: '',
                isVideo: true
            },
		},
		role: '',
		religiousBelief: {
			religiousBeliefId: '',
			name: ''
		}
	}

  const [memorialData, setMemorialData] = useState<MemorialType>(memorialDefault); // initial data

  useEffect(() => {
    debug("Memorial Context", JSON.stringify(memorialData));
  }, [memorialData]);

  return (
    // <Suspense fallback={
    //   <div className="spinner-border text-light" role="status">
    //       <span className="visually-hidden">Loading...</span>
    //   </div>}
    // >
		<MemorialContext.Provider value={{ memorialData, setMemorialData }}>
			{props.children}
		</MemorialContext.Provider>
    // </Suspense>
  );
};

